import React, { useEffect, useState } from 'react';
import './Header.scss';
import { ReactComponent as Logo } from '../../../assets/icons/logo.svg';
import { DesktopMenu } from '../menu/desktop-menu/DesktopMenu';
import { ReactComponent as InstagramIcon } from '../../../assets/icons/instagram.svg';
import { ReactComponent as FacebookIcon } from '../../../assets/icons/facebook.svg';
import { CoreService } from '../../../services/core.service';
import { MobileMenu } from '../menu/mobile-menu/MobileMenu';
import { useLocation } from 'react-router-dom';

export const Header = (): JSX.Element => {
  const siteData = CoreService.siteData;
  const header = siteData.header;
  const mobileMediaQuery = window.matchMedia('(min-width: 480px)');
  const desktopMediaQuery = window.matchMedia('(min-width: 1280px)');
  const location = useLocation().pathname;
  const [backgroundImage, setBackgroundImage ] = useState({backgroundImage: ""});
  const backgroundImages = [
    "images/background-gym-image.jpeg",
    "images/001.jpeg",
    "images/002.jpeg",
  ]

  const getHeading = (): string => {
    if (location === "/about") {
      return 'ABOUT';
    }

    if (location === "/gallery") {
      return 'PHOTOS';
    }

    if (location === '/contact') {
      return 'GET IN TOUCH';
    }

    return header.heading;
  }

  useEffect((): any => {
    let counter = 1;
    const intervalId = setInterval((): void => {
      const url = backgroundImages[counter];
      setBackgroundImage({backgroundImage: `url(${url})`});

      counter++;

      if (counter === backgroundImages.length) {
        counter = 0;
      }
    }, 5000);

    return () => {
      clearInterval(intervalId);
    }
  }, [])

  return (
    <header className="Header-header" data-testid="HeaderContainer" style={backgroundImage}>
      {<div className="Header-logo" data-testid="HeaderLogo">
        <Logo />
      </div>}
      { desktopMediaQuery.matches ? <DesktopMenu/> : <MobileMenu />}
      <div className="Header-overlay" data-testid="HeaderOverlay">
        <h1 className="Header-heading">{getHeading()}</h1>
        {
          location === '/contact' ? <div>
            <a className="Header-contactPhone"
               href="tel:07481 191010">Phone: 07481 191010</a>
          </div> : <></>
        }
        <div className="Header-openingTimes">
          <div className="Header-openingTimesItem">
            MONDAY - FRIDAY: {siteData.openingTimes.weekdays}
          </div>
          {
            mobileMediaQuery.matches && <div className="Header-openingTimesItem">|</div>
          }
          <div className="Header-openingTimesItem">
            SATURDAY - SUNDAY: {siteData.openingTimes.weekends}
          </div>
        </div>
        <div className="Header-socialsContainer">
          <InstagramIcon className="Header-icon"/>
          <FacebookIcon className="Header-icon"/>
        </div>
      </div>
    </header>
  )
}
