import React from 'react';
import './Contact.scss';

export const Contact = (): JSX.Element => {
  return (
    <div className="Contact-container">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3662.997293408228!2d-1.48348315459443!3d52.90828933697045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4879f17570ca60b7%3A0xa664aaac6cb51a3b!2sIron%20World%20Gym%20Derby%C2%B9!5e0!3m2!1sen!2suk!4v1668630847303!5m2!1sen!2suk"
        width="100%"
        height="500px"
        allowFullScreen={false}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"></iframe>
    </div>
  )
}
