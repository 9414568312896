import React, { Fragment, useRef } from 'react';
import './MobileMenu.scss';
import { IMenuItem } from '../menu.types';
import { CoreService } from '../../../../services/core.service';
import { useNavigate } from 'react-router-dom';

export const MobileMenu = (): JSX.Element => {
  const backDropRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const menuItems: IMenuItem[] = CoreService.siteData.header.menu;
  const onMenuItemClick = (i: IMenuItem): void => {
    navigate(i.url);
  }
  const handleMenuClick = (): void => {
    if (backDropRef.current) {
      backDropRef.current.classList.add('MobileMenu--revealBackdrop');
    }
  };

  const handleBackdropClick = (): void => {
    if (backDropRef.current) {
      backDropRef.current.classList.remove('MobileMenu--revealBackdrop');
    }
  }


  return (
    <Fragment>
      <button className="MobileMenu-menuAction" onClick={handleMenuClick}>
        <svg className="MobileMenu-menuIcon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0h24v24H0z" fill="none"></path>
          <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
        </svg>
      </button>
      <div className="MobileMenu-container MobileMenu--backdrop"
           data-testid="MobileMenuContainer"
           ref={backDropRef}
           onClick={handleBackdropClick}>
        <div className="MobileMenu-menuActionContainer">
          {
            menuItems.map((mi: IMenuItem, index: number): JSX.Element => {
              return (
                <button className="MobileMenu-menuItemAction"
                        key={mi.label + index}
                        onClick={(): void => {
                          onMenuItemClick(mi);
                        }}>
                  {mi.label}
                </button>
              )
            })
          }
        </div>
      </div>
    </Fragment>
  )
}
