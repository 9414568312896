import React, { Fragment, useRef, useState } from 'react';
import "./ImageViewer.scss";

export const ImageViewer = (props: any): JSX.Element => {
  const [imageModalConfig, setImageModalConfig] = useState({show: false, img: ""});
  const imageContainerRef = useRef(null);
  const extractColumns = (): any => {
    const columns: any[] = [[], [], [], []];
    let columnCount = 0;

    props.images.forEach((img: any, index: number): any => {
      columns[columnCount].push(img);
      columnCount++;

      if (index !== 0) {
        if ((index + 1) % 4 === 0) {
          columnCount = 0;
        }
      }


      if (index === props.images.length - 1) {
        return columns;
      }
    });

    return columns;
  }
  const cols = extractColumns();
  return (
    <Fragment>
      <div className="ImageViewer-imagesContainer">
        {
          cols.map((c: string[], colIndex: number): any => {
            return (
              <div className="ImageViewer-showcaseImageColumn" key={`column${colIndex}`}>
                {
                  c.map((i: string, imgIndex: number): any => {
                    return (
                      <img className="ImageViewer-showcaseImage"
                           key={`img${imgIndex}`}
                           src={i}
                           alt=""
                           onClick={(): void => {
                             setImageModalConfig({show: true, img: i});
                           }}/>
                    )
                  })
                }
              </div>
            )
          })
        }
      </div>
      {
        imageModalConfig.show && (
          <div className="ImageViewer--backdrop" onClick={(): void => {
            setImageModalConfig({show: false, img: ""})
          }}>
            <div className="ImageViewer-container" ref={imageContainerRef}>
              <img className="ImageViewer-image" onLoad={(e): void => {
                if (imageContainerRef.current) {
                  const target = (e.target as HTMLImageElement);
                  if (target.clientWidth > 1024) {
                    target.style.width = `${1024}px`;
                  }
                }
              }} src={imageModalConfig.img} alt=""/>
            </div>
          </div>
        )
      }
    </Fragment>
  )
};
