import { ImageViewer } from '../../ux/image-viewer/ImageViewer';

export const Gallery = (): JSX.Element => {
  const galleryImages = [
    "/images/014.jpg",
    "/images/004.jpg",
    "/images/006.jpg",
    "/images/011.jpg",
    "/images/008.jpg",
    "/images/017.jpg",
    "/images/010.jpg",
    "/images/019.jpg"
  ];
  return (
    <div className="Gallery-container">
     <ImageViewer images={galleryImages}/>
    </div>
  )
}
