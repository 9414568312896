import React from 'react';
import './DesktopMenu.scss';
import { IMenuItem } from '../menu.types';
import { useNavigate } from 'react-router-dom';
import { CoreService } from '../../../../services/core.service';

export const DesktopMenu = (): JSX.Element => {
  const menuItems: IMenuItem[] = CoreService.siteData.header.menu;
  const navigate = useNavigate();

  const onMenuItemClick = (i: IMenuItem): void => {
    navigate(i.url);
  }

  return (
    <menu className="DesktopMenu-menu" data-testid="DesktopMenuContainer">
      {
        menuItems.map((mi: IMenuItem, index: number): JSX.Element => {
          return (
            <button className="DesktopMenu-menuItemAction"
                    key={mi.label + index}
                    onClick={(): void => {
                      onMenuItemClick(mi);
                    }}>
              {mi.label}
            </button>
          )
        })
      }
    </menu>
  )
}
