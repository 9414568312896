import React from 'react';
import './App.scss';
import { IMembership } from './assets/data/data.types';
import { CoreService } from './services/core.service';

function App() {
  const siteData = CoreService.siteData;
  const memberships: IMembership[] = siteData.home.memberships;
  const showCaseImages = siteData.home.photoMenus.equipment;
  const expertAdviceImages = siteData.home.photoMenus.expertAdvice;
  return (
    <div className="App-container" data-testid="AppContainer">
      <section className="App-sectionContainer">
        <h2 className="App-sectionHeading">{siteData.home.intro.heading}</h2>
        <h4 className="App-sectionSubHeading">{siteData.home.intro.subHeading}</h4>
        <p className="App-sectionHeadingDescription">{siteData.home.intro.description}</p>
      </section>
      <div className="App-showcase">
        {
          showCaseImages.map((img, index): JSX.Element => {
            return (
              <div className="App-showcaseImageContainer" key={img.alt + index}>
                <img className="App-showCaseImage" src={img.src} alt=""/>
                <h3 className="App-showcaseImageCaption">{img.label}</h3>
              </div>
            )
          })
        }
      </div>
      <section className="App-sectionContainer">
        <h1 className="App-sectionHeading">{siteData.home.expertAdvice.heading}</h1>
        <p className="App-sectionHeadingDescription">{siteData.home.expertAdvice.description}</p>
      </section>
      <section className="App-expertAdviceSection">
        {
          expertAdviceImages.map((img, index): JSX.Element => {
            return (
              <div className="App-adviceImagesContainer" key={img.alt + index}>
                <img className="App-adviceImage" src={img.src} alt=""/>
                <h3 className="App-adviceImageCaption">{img.label}</h3>
              </div>
            )
          })
        }
      </section>
      <section className="App-sectionContainer">
        <h1 className="App-sectionHeading">MEMBERSHIP PRICING</h1>
        <p className="App-sectionHeadingDescription">See below for affordable gym memberships </p>
      </section>
      <section className="App-membershipsSection">
        <div className="App-membershipsSectionScroller">
          {
            memberships.map((m: IMembership, index: number): JSX.Element => {
              return (
                <div className={`App-membershipContainer${index % 2 !== 0 ? ' App--changeBackground' : ''}`} key={index.toString()}>
                  <h3 className="App-membershipLabel">{m.label}</h3>
                  <div className="App-pricingContainer">
                    <sup className="App-currency">£</sup>
                    <span className="App-price">{m.price}</span>
                  </div>
                  <p className="App-membershipDescription">{m.description}</p>
                  <p className="App-membershipValid">{m.valid}</p>
                  {/*<button className="App-chooseAction">Choose</button>*/}
                  <a href="https://secure.ashbournemanagement.co.uk/signupUK/index.aspx?fn=GRCY7" target="_blank">Choose</a>
                </div>
              )
            })
          }
        </div>
      </section>
    </div>
  );
}

export default App;
