import React from 'react';
import './Footer.scss';
import { ReactComponent as InstagramIcon } from '../../../assets/icons/instagram.svg';
import { ReactComponent as FacebookIcon } from '../../../assets/icons/facebook.svg';
import { CoreService } from '../../../services/core.service';

export const Footer = (): JSX.Element => {
  const siteData = CoreService.siteData;
  const footer = siteData.footer;
  return (
    <footer className="Footer-container" data-testid="FooterContainer">
      <div className="Footer-top">
        <div className="Footer-contactDetails" data-testid="FooterContactDetails">
          <h1 className="Footer-heading">{footer.gymHeading}</h1>
          <span className="Footer-address">{footer.address.street}</span>
          <span className="Footer-address">{footer.address.city}</span>
          <span className="Footer-address Footer--defaultSpacingTop">{footer.address.postCode}</span>
          <span className="Footer-address">{footer.address.Telephone}</span>
        </div>
        <div className="Footer-socialMedia" data-testid="FooterSocialMedia">
          <h1 className="Footer-heading">{footer.socialMediaHeading}</h1>
          <div>
            <InstagramIcon className="Footer-iconLarge"/>
            <FacebookIcon className="Footer-iconLarge"/>
          </div>
        </div>
        <div className="Footer-workingHours" data-testid="FooterWorkingHours">
          <h1 className="Footer-heading">{footer.gymHoursHeading}</h1>
          <span className="Footer-gymHours">MONDAY - FRIDAY: {siteData.openingTimes.weekdays}</span>
          <span className="Footer-gymHours">SATURDAY - SUNDAY: {siteData.openingTimes.weekends}</span>
        </div>
      </div>
      <div className="Footer-bottom" data-testid="FooterSignature">
        <span>&copy; {footer.signature}</span>
      </div>
    </footer>
  )
}
