import React, { useEffect, useRef, useState } from 'react';
import './About.scss';
import { CoreService } from '../../../services/core.service';
import { ImageViewer } from '../../ux/image-viewer/ImageViewer';

export const About = (): JSX.Element => {
  const siteData = CoreService.siteData;
  const aboutData = siteData.about;
  const aboutImages = [
    "/images/014.jpg",
    "/images/004.jpg",
    "/images/006.jpg",
    "/images/011.jpg",
    "/images/008.jpg",
    "/images/017.jpg",
    "/images/010.jpg",
    "/images/019.jpg"
  ];





  return (
    <div className="About-container">
      <section className="About-section">
        <div className="About-sectionHeadingAndDescription">
          <h2 className="About-sectionHeading">{aboutData.mission.heading}</h2>
          <p className="About-sectionDescription">{aboutData.mission.description}</p>
        </div>
        <div className="About-sectionImageContainer">
          <img className="About-sectionImage" src={aboutData.mission.image} alt=""/>
        </div>
      </section>
      <section className="About-section About--sectionTopMargin">
        <div className="About-sectionImageContainer">
          <img className="About-sectionImage" src={aboutData.history.image} alt=""/>
        </div>
        <div className="About-sectionHeadingAndDescription">
          <h2 className="About-sectionHeading">{aboutData.history.heading}</h2>
          <p className="About-sectionDescription">{aboutData.history.description}</p>
        </div>
      </section>
      <section className="About-section About-sectionShowcase">
        <h2 className="About-sectionHeading About--centreHeading">PHOTOS</h2>
        <ImageViewer images={aboutImages}/>
      </section>
    </div>
  )
}
